import {FAILED, REQUEST, SUCCESS} from "../../../services/const";
import {toast} from "react-toastify";
import {call} from "../../../services/calls/calls";
import {API_PRODUCTS} from "../../../services/const/api";
import {parseToQuery} from "../../../services/functions/functions";

const GET_PRODUCT = "GET_PRODUCT"

const DEFAULT_STATE = {
    fetching: false,
    data: {}
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_PRODUCT:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_PRODUCT:
            return {
                ...state,
                fetching: false,
                data: payload.data[0] || DEFAULT_STATE.data
            }

        case FAILED + GET_PRODUCT:
            toast.error("Не удалось получить информацию о продукте")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getProduct = (obj) => call(
    'GET',
    `${API_PRODUCTS}${parseToQuery(obj)}`,
    GET_PRODUCT,
)