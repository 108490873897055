import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import {PRODUCTS_PATH} from "../../../services/router/pages";
import Layout from "../../../layout/Layout";
import {Container} from "@material-ui/core";
import Breadcrumbs from "../../../stories/breadcrumbs/Breadcrumbs";
import Header from "../../../theme/components/Header";
import Parser from "../../../stories/parser/Parser";
import {connect} from "react-redux";
import {getProduct} from "./reducer";
import TrueGrid from "../../../theme/components/TrueGrid";
import useMobile from "../../../services/hooks/useMobile";
import {BY_URL, WITH_MEDIA} from "../../../services/const/api";
import Loader from "../../../theme/components/Loader";
import EmptyPage from "../../../theme/components/EmptyPage";
import ProductFiles from "./components/ProductFiles";
import CategoryChip from "../../../stories/category/CategoryChip";
import ProductAdvantages from "./components/ProductAdvantages";
import FlexContainer from "../../../theme/components/FlexContainer";
import {palette} from "../../../theme/Theme";
import ProductCta from "./components/ProductCta";
import Lightbox from "yet-another-react-lightbox";
import useLightbox from "../../../services/hooks/useLightbox";

const Product = (props) => {
    const {openLightbox, slides, addSlide, closeBox} = useLightbox()
    const {productId} = useParams()
    const isMobile = useMobile()

    function listener() {
        const imgList = document.querySelectorAll("#canOpenInFull")
        console.log(imgList)
        if (!imgList || !imgList?.length) return
        imgList.forEach(el=>{
            el.addEventListener("click", ()=>{
                console.log("!!!!!")
                addSlide(el.src)
            })
        })
    }
    const ref = useRef(null)

    useEffect(()=>{
        props.getProduct({
            [BY_URL]: productId,
            ...WITH_MEDIA
        })
    }, [])

    const content = props.data?.attributes

    const pages = [
        {link: PRODUCTS_PATH, name: "Продукты"},
    ]

    return (
        <Layout title={content?.title}  fetching={props.fetching}>
            {/*<PageHeader title={content?.head} center/>*/}
            <Lightbox
                open={openLightbox}
                close={closeBox}
                slides={slides}
                render={{
                    buttonPrev: () => null,
                    buttonNext: () => null,
                }}
            />

            <Container>
                <Breadcrumbs pages={pages} current={"Страница продукта"}/>
                { props.fetching?
                    <Loader/>:
                    !content?
                        <EmptyPage/>:
                        <>
                            <TrueGrid template={isMobile ? "1fr" : "auto 1fr"} className={"mt-7"} alignItems={"center"} gap={isMobile? 15: 30}>
                                <img src={content?.logoUrl} style={{height: 120, margin: "auto", background: palette.back2, borderRadius: 20, padding: 20, maxWidth: 250, objectFit: "contain"}}/>
                                <div>
                                    <div className={"flex items-center"} style={{
                                        gap: isMobile ? 0 : 20,
                                        flexDirection: isMobile ? "column" : "",
                                        marginBottom: isMobile ? 20 : 0,
                                    }}>
                                        <Header h={1} title={content?.title} className={"mb-1"}/>
                                        <CategoryChip chip={content?.category?.data?.attributes} product/>
                                    </div>
                                    <p>{content?.description}</p>
                                </div>
                            </TrueGrid>

                            <div className={"mt-7"}>
                                <Parser text={content?.text}/>
                            </div>

                            {content?.json && <ProductAdvantages json={content?.json}/>}
                            {content?.json && <ProductFiles json={content?.json}/>}

                            <ProductCta/>
                        </>
                }
            </Container>
        </Layout>
    );
};
const mapStateToProps = (state) => ({
    ...state.products.product
});

export default connect(mapStateToProps,{
    getProduct
})(Product);
